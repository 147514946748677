<template>
    <div class="fourzerofour">
        <heads></heads>
        <div class="four_body">
            <img src="@/assets/img/404.png">
            <h4>Hi！真不巧，网页走丢了。</h4>
        </div>
        <foots></foots>
    </div>
</template>
<script>
    import heads from '@/components/head/index.vue'
    import foots from '@/components/foot/index.vue'
    export default {
        data() {
            return {

            }
        },
        components: {
            heads,
            foots,
        },
        methods: {

        }
    }
</script>
<style lang="scss" scoped>
    h4 {
        font-size: 24px;
        color: #454545;
    }
    .four_body{
        padding:80px 0;
    }
</style>